.page-header {
  background-color: #000000;
  font-size: 20px;
  margin: 0;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 99;
  height: 50px;
  display: flex;
  align-items: center;
}
.text {
  color: white;
  font-size: 20px;
  font-weight: bold;
  margin-left: 20px;
}
